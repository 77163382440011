import { FC } from 'react';

import Layout from 'components/layout';
import BackButton from 'components/common/BackButton';

const Terms: FC = () => (
  <Layout noindex>
    <div className='terms-container agreements'>
      <BackButton />
      <h1 className='responsive-title-1'>Terms of service</h1>
      <div className='terms'>
        <div className='terms-content'>
          <ol className='top-ordered-list'>
            <li>
              <h3>Acceptance of Terms</h3>
              <p>
                By using this Web site (the “Site”) in any way, including using, transmitting,
                downloading, or uploading any of the services or functionality (the “Service”) made
                available or enabled via the Site by Alpaca Guild Limited, or merely browsing the
                Site, you agree to these Terms of Service. You may not use the Service, or accept
                these Terms of Service, if (a) you are not of legal age to form a binding contract
                with Alpaca Guild Ltd; or (b) you are prohibited by law from receiving or using the
                Service. If you are entering into these Terms of Service on behalf of a company or
                other legal entity, you represent that you have the authority to bind such entity to
                these Terms of Service, in which case “you” or “your” shall refer to such entity.
                Alpaca Guild Limited makes the Service available only if you have registered for an
                account. The Service may also be subject to a subscription or other agreement,
                posted guidelines, rules or terms of service (“Additional Terms”). If there is any
                conflict between these Terms of Service and the Additional Terms, the Additional
                Terms take precedence in relation to the Service. These Terms of Service, and any
                applicable Additional Terms, are referred to collectively as the “Terms.”
              </p>
            </li>

            <li>
              <h3>Modification of the Terms</h3>
              <p>
                Alpaca Guild Limited may change the Terms from time to time at its sole discretion,
                and if Alpaca Guild Limited makes any substantial changes, we will notify you by
                sending you an e-mail to the last e-mail address you provided to us and/or by
                posting notice of the change on the Site. Any material changes to these Terms will
                be effective upon the earlier of thirty (30) calendar days following our dispatch of
                an e-mail notice to you or thirty (30) calendar days following our posting of notice
                of the changes on our Site. These changes will be effective immediately for new
                users of our Site or Service. Alpaca Guild Limited may require you to provide
                consent to the updated Terms in a specified manner before further use of the Service
                is permitted. Otherwise, your continued use of the Site or the Service constitutes
                your acceptance of the changes. Please regularly check the Site to view the
                then-current Terms.
              </p>
            </li>
            <li>
              <h3>Definitions</h3>
              <p>
                Capitalised terms shall have the meanings set forth in this section, or in the
                section where they are first used.
              </p>
              <ol>
                <li>
                  <h4>“Content”</h4>
                  <p>
                    means, without limitation, any and all information, data, software code,
                    results, ideas, plans, sketches, texts, files, links, images, photos, video,
                    sound, inventions (whether or not patentable), notes, works of authorship,
                    articles, feedback, or other materials.
                  </p>
                </li>

                <li>
                  <h4>“Customer Content”</h4>
                  <p>
                    means any Content, including Alpaca Content, provided, imported or uploaded to,
                    or otherwise used by you or on your behalf with the Services.
                  </p>
                </li>

                <li>
                  <h4>“Documentation”</h4>
                  <p>
                    means all specifications, user manuals, and other technical materials relating
                    to the Services.
                  </p>
                </li>

                <li>
                  <h4>“Intellectual Property Rights”</h4>
                  <p>
                    means any and all now known or hereafter existing (a) rights associated with
                    works of authorship, including copyrights, mask work rights, and moral rights;
                    (b) trademark or service mark rights; (c) trade secret rights; (d) patents,
                    patent rights, and industrial property rights; (e) layout design rights, design
                    rights, and other proprietary rights of every kind and nature other than
                    trademarks, service marks, trade dress, and similar rights; and (f) all
                    registrations, applications, renewals, extensions, or reissues of the foregoing,
                    in each case in any jurisdiction throughout the world.
                  </p>
                </li>

                <li>
                  <h4>“Named Users”</h4>
                  <p>
                    means Users that have been specifically identified by you and for whom you will
                    pay the applicable fee.
                  </p>
                </li>

                <li>
                  <h4>“Order Form”</h4>
                  <p>
                    means the form evidencing the initial subscription for the Service and any
                    subsequent order forms submitted online or in written form and specifying, among
                    other things, the number of licenses and other service contracted for, the
                    applicable fees, the billing period, and other charges as agreed to between the
                    parties each order form to be incorporated into and become a part of these
                    Terms.
                  </p>
                </li>

                <li>
                  <h4>Alpaca</h4>
                  <p>means a record of an animal (Alpaca) created by a User on the Service.</p>
                </li>

                <li>
                  <h4>“Alpaca Administrator(s)”</h4>
                  <p>
                    means, with respect to a particular Alpaca, those Users designated by you who
                    are authorised to assign access rights to Users and otherwise administer the
                    Alpaca record.
                  </p>
                </li>

                <li>
                  <h4>“Alpaca Content”</h4>
                  <p>
                    means, with respect to a particular Alpaca, any Content provided, imported or
                    uploaded to the Service in connection with such Alpaca.
                  </p>
                </li>

                <li>
                  <h4>“User”</h4>
                  <p>means a user of the Service.</p>
                </li>
              </ol>
            </li>

            <li>
              <h3>Alpaca</h3>
              <ol>
                <li>
                  <h4>Alpaca License</h4>
                </li>
                <li>
                  <h4>Alpaca Rights</h4>
                </li>
                <li>
                  <h4>Recognised Registry</h4>
                </li>
              </ol>
            </li>

            <li>
              <h3>License Grant and Restrictions</h3>

              <ol>
                <li>
                  <h4>License Grant</h4>
                  <p>
                    Subject to the terms and conditions of these Terms, Alpaca Guild Limited grants
                    you a non-exclusive, non-transferable license to use the Service solely for your
                    internal business purposes during the Term.
                  </p>
                </li>

                <li>
                  <h4>Limitations</h4>
                  <p>
                    You agree that you will not: (a) permit any party to access and/or use the
                    Service, other than the Users authorised under these Terms; (b) rent, lease,
                    loan, or sell access to the Service to any third party; (c) interfere with,
                    disrupt, alter, translate, or modify the Service or any part thereof, or create
                    an undue burden on the Service or the networks or services connected to the
                    Service (including, without limitation, any external websites that contain third
                    party content and that are linked to via the Service); (d) except as permitted
                    by applicable law, reverse engineer or access the Service in order to (i) build
                    a competitive product or service, (ii) build a product using similar ideas,
                    features, functions or graphics of the Service, or (iii) copy any ideas,
                    features, functions or graphics of the Service; (e) with respect to a particular
                    Alpaca, exceed or attempt to exceed the scope of the access rights granted to
                    you for such Alpaca; (f) without Alpaca Guild Limited’s express written
                    permission, introduce software or automated agents or scripts to the Service so
                    as to produce multiple accounts, generate automated searches, requests and
                    queries, or to strip or mine data from the Service; (g) perform or publish any
                    performance or benchmark tests or analyses relating to the Service or the use
                    thereof; or (h) cover or obscure any page or part of the Service via HTML/CSS,
                    scripting, or any other means. Except as expressly set forth in these Terms, no
                    express or implied license or right of any kind is granted to you regarding the
                    Service or any part of the Service.
                  </p>
                </li>

                <li>
                  <h4>License to Alpaca Guild Limited Content</h4>
                  <p>
                    Certain Content is provided through the Service by Alpaca Guild Limited. Subject
                    to the terms and conditions of these Terms, Alpaca Guild Limited hereby grants
                    you a non-exclusive, non-transferable license to view, download and print
                    Content provided by Alpaca Guild Limited (“Alpaca Guild Content”), subject to
                    the following conditions: (a) you may access and use the Alpaca Guild Content
                    solely for personal, informational, non-commercial and internal purposes, in
                    accordance with these Terms; (b) you may not modify or alter the Alpaca Guild
                    Content; (c) you may not distribute or sell, rent, lease, license or otherwise
                    make the Alpaca Guild Content available to others; (d) you may not remove any
                    copyright or other proprietary notices contained in the Alpaca Guild Content;
                    and (d) you may not copy or distribute any photos, graphics, audio or video in
                    the Alpaca Guild Content apart from their accompanying text. Alpaca Guild
                    Limited reserves the right to revoke the authorisation to view, download and
                    print the Alpaca Guild Content at any time. The rights specified above are not
                    applicable to the design, layout or look and feel of the Site. Such elements of
                    the Site are protected by Intellectual Property Rights and may not be copied or
                    imitated in whole or in part. No mark, graphic, sound or image from the Site may
                    be copied or retransmitted unless expressly permitted by Alpaca Guild Limited.
                  </p>
                </li>

                <li>
                  <h4>License to Other User Content</h4>
                  <p>
                    Certain areas of the Site, such as the general wiki and the forums, may allow
                    Users to share Content with other Users. Subject to the terms and conditions of
                    these Terms, Alpaca Guild Limited hereby grants you a non-exclusive,
                    non-transferable license to view, download and print Content (other than Alpaca
                    Content) provided by other Users (“Other User Content”), subject to the
                    following conditions: (a) you may access and use the Other User Content solely
                    for personal, informational, non-commercial and internal purposes, in accordance
                    with these Terms; (b) you may not modify or alter the Other User Content; (c)
                    you may not distribute or sell, rent, lease, license or otherwise make the Other
                    User Content available to others; (d) you may not remove any copyright or other
                    proprietary notices contained in the Other User Content; and (d) you may not
                    copy or distribute any photos, graphics, audio or video in the Other User
                    Content apart from their accompanying text. Alpaca Guild Limited reserves the
                    right to revoke the authorisation to view, download and print the Other User
                    Content at any time.
                  </p>
                </li>

                <li>
                  <h4>License to Alpaca Content</h4>
                  <p>
                    Your rights with respect to any Alpaca Content to which you have access is
                    governed solely by the terms and conditions of the Project License specified for
                    such Alpaca Content; provided that if no Alpaca License is specified, such
                    Alpaca Content will be subject to Section 5.4 as such Section applies to Other
                    User Content.
                  </p>
                </li>

                <li>
                  <h4>Usernames and Passwords</h4>
                  <p>
                    When you register, Alpaca Guild Limited will ask you to provide a unique
                    username and password to enable you to access the Service pursuant to these
                    Terms. Alpaca Guild Limited reserves the right to require you to change your
                    username and password in Alpaca Guild Limited’s sole discretion from time to
                    time. You are responsible for maintaining the confidentiality of your username
                    and password, and are solely responsible for all activities that occur under
                    your username. You agree (a) not to allow a third party to use your account,
                    username or password at any time; and (b) to notify Alpaca Guild Limited
                    promptly of any actual or suspected unauthorised use of your account, username
                    or password, or any other breach or suspected breach of these Terms. Alpaca
                    Guild Limited reserves the right to terminate any username and password, which
                    Alpaca Guild Limited reasonably determines may have been used by an unauthorised
                    third party.
                  </p>
                </li>

                <li>
                  <h4>IP Ownership</h4>
                  <p>
                    The Service and all Intellectual Property Rights in the Service, the Alpaca
                    Guild Limited Content and any of Alpaca Guild Limited’s proprietary technology,
                    including software, hardware, products, processes, algorithms, user interfaces,
                    know-how, technologies, designs and other tangible or intangible technical
                    material or information made available to you by Alpaca Guild Limited in
                    providing the Service(the “Alpaca Guild Limited Technology”) are the exclusive
                    property of Alpaca Guild Limited or its suppliers. You hereby assign to Alpaca
                    Guild Limited any suggestions, ideas, enhancement requests, feedback,
                    recommendations or other information provided by you related to the Service, the
                    Alpaca Guild Limited Content or the Alpaca Guild Limited Technology. Except as
                    expressly set forth herein, no express or implied license or right of any kind
                    is granted to you regarding the Service, the Alpaca Guild Limited Content or the
                    Alpaca Guild Limited Technology, or any part thereof, including any right to
                    obtain possession of any source code, data or other technical material relating
                    to the Alpaca Guild Limited Technology. All rights not expressly granted to you
                    are reserved to Alpaca Guild Limited.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h3>Payment</h3>
              <ol>
                <li>
                  <h4>Charges</h4>
                  <p>
                    If you sign up for a Service that requires the payment of a fee, you shall pay
                    all fees or charges to your account in accordance with the fees, charges, and
                    billing terms in effect at the time a fee or charge is due and payable. The
                    initial charge will be equal to the license fee applicable to the number of
                    Named User licenses designated in your account (“License Fee”) as set forth on
                    the Site. Payments must be made monthly in advance unless otherwise mutually
                    agreed upon. All payment obligations are non-cancellable and all amounts paid
                    are non-refundable. You must provide Alpaca Guild Limited with a valid credit
                    card or valid online payment account information as a condition to registering
                    for the Service. Alpaca Guild Limited reserves the right to modify its fees and
                    charges and to introduce new charges at any time, upon at least thirty (30) days
                    prior notice to you, which notice may be provided by e-mail.
                  </p>
                </li>

                <li>
                  <h4>Billing and Renewal</h4>
                  <p>
                    Alpaca Guild Limited charges and collects in advance for use of the Service.
                    Alpaca Guild Limited will automatically renew and bill your credit card or
                    online payment account each month. The renewal charge will be equal to the
                    License Fee in effect during the prior month, unless Alpaca Guild Limited has
                    given you at least thirty (30) days prior written notice of a fee increase,
                    which shall be effective upon renewal and thereafter. Any amounts not paid when
                    due shall bear interest at the rate of one and one half percent (1.5%) per
                    month, or the maximum legal rate, if less. Alpaca Guild Limited shall be
                    entitled to withhold performance and discontinue Service until all amounts due
                    are paid in full. Alpaca Guild Limited’s fees are exclusive of all taxes, levies
                    or duties imposed by taxing authorities, and you shall be responsible for
                    payment of all such taxes, levies, or duties, excluding only taxes based solely
                    on Alpaca Guild Limited’s income. You agree to provide Alpaca Guild Limited with
                    complete and accurate billing and contact information. This information includes
                    your legal company name, street address, e-mail address and name and telephone
                    number of an authorised billing contact and Alpaca Administrator. You agree to
                    update this information within thirty (30) days of any change to it. If the
                    contact information you have provided is false or fraudulent, Alpaca Guild
                    Limited may terminate your access to the Service in addition to any other legal
                    remedies.
                  </p>
                </li>

                <li>
                  <h4>Named Groups (Herd, Flock or Other Group Name)</h4>
                  <p>
                    You are responsible for paying for the number of Named Groups designated in your
                    account, whether or not the Named Group uses the Services. You may add Named
                    Groups by designating the number of Named Groups you desire in your account.
                    Added Named Groups will be subject to the following: (a) the fee applicable to
                    Named Groups after such addition will be the then-current fee set forth on the
                    Site applicable to the total number of Named Groups designated in your account
                    and (b) any increase to the fees as a result of the addition of a Named Group in
                    the middle of a billing month will be charged in full for that billing month.
                    You may transfer a Named Group license to another User, provided that you may
                    not exceed the number of Named Groups for which you have paid the applicable
                    License Fees. You acknowledge that if you have been designated as a Named User
                    by another User (“Paying User”), notwithstanding anything to the contrary in
                    this Agreement, Alpaca Guild Limited shall be entitled to withhold performance
                    and discontinue Service to you until all amounts due on your account (including,
                    but not limited to, your License Fee) are paid in full.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Customer Content and Conduct</h3>
              <ol>
                <li>
                  <h4>License; Warranty.</h4>
                  <p>
                    You grant Alpaca Guild Limited a non-exclusive, worldwide, royalty-free and
                    fully paid license (a) to use the Customer Content in conjunction with the
                    Service; and (b) to use your trademarks, service marks, and logos as required to
                    provide the Service. All rights in and to the Customer Content not expressly
                    granted to Alpaca Guild Limited in these Terms are reserved by you.
                  </p>
                </li>

                <li>
                  <h4>Content and Conduct.</h4>
                  <p>
                    You represent and warrant that any Customer Content shall not (a) infringe any
                    copyright, trademark, or patent; (b) misappropriate any trade secret; (c) be
                    deceptive, defamatory, obscene, pornographic, invasive of another’s privacy,
                    hateful, or racially, ethnically or otherwise objectionable; (d) contain any
                    viruses, worms or other malicious computer programming codes able to damage the
                    Service, any Content, or other data of the Service; or (e) otherwise violate the
                    rights of a third party. In addition, you agree not to use, or encourage or
                    permit others to use, the Site or Service to (i) stalk and/or harass another;
                    (ii) harm minors in any way; (iii) impersonate any person or entity, or falsely
                    state or otherwise misrepresent your affiliation with a person or entity; (iv)
                    forge headers or otherwise manipulate identifiers to disguise the origin of any
                    Content posted on or transmitted through the Service; (v) use the Site, Service
                    or Content such that it will mislead a third party into believing that he or she
                    is interacting directly with Alpaca Guild Limited or the Service; (vi) engage in
                    any chain letters, contests, junk e-mail, pyramid schemes, spamming, surveys or
                    other duplicative or unsolicited messages (commercial or otherwise); (vii) use
                    any Alpaca Guild Limited domain name as a pseudonymous return e-mail address;
                    (viii) access or use the Site or Service in any manner that could damage,
                    disable, overburden or impair any Alpaca Guild Limited server or the networks
                    connected to any Alpaca Guild Limited server; or (ix) market any goods or
                    services for any business purposes (including advertising and making offers to
                    buy or sell goods or services), unless specifically allowed to do so by Alpaca
                    Guild Limited. You will defend at your expense any suit brought against Alpaca
                    Guild Limited and will pay any settlement you make or approve or any damages
                    finally awarded in such suit insofar as such suit is based on a claim by any
                    third party based upon, resulting from or related to any improper or
                    unauthorised use of the Service by you or any Customer Content.
                  </p>
                </li>

                <li>
                  <h4>Storage; Security and Backups.</h4>
                  <p>
                    Alpaca Guild Limited reserves the right to establish capacity limits on your
                    storage of Customer Content on the Service. Alpaca Guild Limited shall provide
                    logical and physical security to ensure the logical and physical security of its
                    equipment and Customer Content. The logical and physical security provided by
                    Alpaca Guild Limited will include, but not be limited to (a) a defined security
                    administration practice and process including account and password
                    administration; and (b) ensuring that only authorised Alpaca Guild Limited
                    employees and contractors who have a need for physical access to provide the
                    Service will have such access to the equipment and Customer Content. During the
                    Term, Alpaca Guild Limited shall use commercially reasonable efforts to protect
                    Customer Content behind a secure firewall system, to conduct daily data backups,
                    and to store weekly full-system backups in a separate, fire-resistant facility.
                  </p>
                </li>

                <li>
                  <h4>Content Loss.</h4>
                  <p>
                    You represent and warrant that you shall keep and maintain your own copy of all
                    Alpaca Content that is provided to the Service and Alpaca Guild Limited. Except
                    as set forth below, Alpaca Guild Limited is not obligated to back up any Alpaca
                    Content that is posted on the Service. Alpaca Guild Limited therefore recommends
                    that you create backup copies of any Alpaca Content posted on the Service at
                    your sole cost and expense. You agree that any use of the Service contrary to or
                    in violation of your representations and warranties in this section constitutes
                    improper and unauthorised use of the Service. You agree that Alpaca Guild
                    Limited may (but has no obligation to), in Alpaca Guild Limited’s sole
                    discretion, remove or modify any Alpaca Content which it deems to violate your
                    representations and warranties in this section. In the event of a loss of Alpaca
                    Content caused by Alpaca Guild Limited, Alpaca Guild Limited will use
                    commercially reasonable efforts to recover the Alpaca Content within ninety-six
                    (96) hours of notification of loss. If Alpaca Guild Limited performs recovery
                    services for a loss of Customer Content not caused by Alpaca Guild Limited, you
                    agree to pay Alpaca Guild Limited’s then-current time and materials rate to
                    attempt to recover the Alpaca Content. The foregoing constitutes your sole and
                    exclusive remedy for any loss of Customer Content.
                  </p>
                </li>

                <li>
                  <h4>Access to Alpaca Content on Termination.</h4>
                  <p>
                    In the event your access to the Service is terminated (other than by reason of
                    your breach), Alpaca Guild Limited will make available to you a file of the
                    Alpaca Content if requested in writing by you within thirty (30) days after such
                    termination. You agree and acknowledge that Alpaca Guild Limited has no
                    obligation to retain the Alpaca Content, and may delete such Alpaca Content, at
                    any time on or after the thirty-first (31st) day following termination. Alpaca
                    Guild Limited reserves the right to withhold, remove and/or discard Alpaca
                    Content, without notice, for any breach, including, without limitation, your
                    non-payment. Upon termination for cause, your right to access or use Alpaca
                    Content shall immediately cease, and Alpaca Guild Limited will have no
                    obligation to maintain or provide any Alpaca Content.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h3>Third Party Links</h3>
              <p>
                The Site may contain links to other web sites operated by third parties. Such third
                party web sites are not under the control of Alpaca Guild Limited. Alpaca Guild
                Limited is not responsible for the content of any third party web site or any link
                contained in a third party web site. Alpaca Guild Limited provides these links only
                as a convenience and does not review, approve, monitor, endorse, warrant, or make
                any representations with respect to third party web sites.
              </p>
            </li>

            <li>
              <h3>Copyright Policy</h3>
              <p>
                Alpaca Guild Limited reserves the right to terminate its agreement with any User
                about whom Alpaca Guild Limited receives copyright infringement notifications in
                accordance with this Section 9. Without limiting the foregoing, if you believe that
                a copyrighted work has been copied and posted via the Service in a way that
                constitutes copyright infringement, you shall provide Alpaca Guild Limited with the
                following information: (a) an electronic or physical signature of the person
                authorised to act on behalf of the owner of the copyrighted work; (b) an
                identification and location in connection with the Service of the copyrighted work
                that you claim has been infringed; (c) a written statement by you that you have a
                good faith belief that the disputed use is not authorised by the owner, its agent,
                or the law; (d) your name and contact information, such as telephone number or
                e-mail address; and (e) a statement by you that the above information in your notice
                is accurate and, under penalty of perjury, that you are the copyright owner or
                authorised to act on the copyright owner’s behalf. Contact information for Alpaca
                Guild Limited’s Copyright Agent for notice of claims of copyright infringement is as
                follows:
              </p>
            </li>

            <p />

            <li>
              <h3>Limited Warranty and Disclaimer</h3>
              <ol>
                <li>
                  <h4>Limited Warranty</h4>
                  <p>
                    Alpaca Guild Limited warrants that it will provide the Service in a manner
                    consistent with general industry standards reasonably applicable to the
                    provision thereof and that the Service will materially conform to Alpaca Guild
                    Limited’s then current Documentation for the Service under normal use and
                    circumstances. If you notify Alpaca Guild Limited of a breach of warranty,
                    Alpaca Guild Limited will correct or modify the Service so that it conforms to
                    Alpaca Guild Limited’s then current Documentation for the Service. The foregoing
                    constitutes your sole and exclusive remedy for any breach of warranty.
                  </p>
                </li>

                <li>
                  <h4>Disclaimer</h4>
                  <p>
                    The limited warranty set forth in these Terms is made for your benefit only.
                    Except as expressly provided in this section and to the maximum extent permitted
                    by applicable law, the Service and any other services provided by Alpaca Guild
                    Limited are provided “as is,” and Alpaca Guild Limited makes no (and hereby
                    disclaims all) warranties, representations, or conditions, whether written,
                    oral, express, implied or statutory, including, without limitation, any implied
                    warranties of merchantability, title, noninfringement, or fitness for a
                    particular purpose, with respect to the use, misuse, or inability to use the
                    Service (in whole or in part) or any other products or services provided to you
                    by Alpaca Guild Limited. Alpaca Guild Limited does not warrant that all errors
                    can be corrected, or that operation of the Service shall be uninterrupted or
                    error-free.
                  </p>
                </li>

                <li>
                  <h4>Internet Delays</h4>
                  <p>
                    The Service may be subject to limitations, delays, and other problems inherent
                    in the use of the Internet and electronic communications. Alpaca Guild Limited
                    is not responsible for any delays, delivery failures or other damages resulting
                    from such problems.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h3>Limitation of Liability</h3>
              <ol>
                <li>
                  <h4>Types of Damages</h4>
                  <p>
                    To the maximum extent permitted under applicable law, in no event shall Alpaca
                    Guild Limited, or its suppliers, be liable to you for any special, indirect,
                    incidental or consequential damages or any lost profits, including any such
                    damages or costs due to loss of data, use or goodwill, personal or property
                    damage resulting from or in connection with Alpaca Guild Limited’s performance
                    hereunder or the use, misuse, or inability to use the Service or other products
                    or services hereunder, regardless of the cause of action or the theory of
                    liability, whether in tort, contract, or otherwise, even if Alpaca Guild Limited
                    has been notified of the likelihood of such damages. In no event shall Alpaca
                    Guild Limited be liable for procurement costs of substitute products or
                    services.
                  </p>
                </li>

                <li>
                  <h4>Amount of Damages</h4>
                  <p>
                    To the maximum extent permitted under applicable law, the maximum liability of
                    Alpaca Guild Limited arising out of or in any way connected to these Terms shall
                    not exceed the fees paid by you to Alpaca Guild Limited during the Twelve (12)
                    months immediately preceding the claim. The existence of one or more claims
                    under these Terms will not increase Alpaca Guild Limited’s liability. In no
                    event shall Alpaca Guild Limited’s suppliers have any liability arising out of
                    or in any way connected to these Terms.
                  </p>
                </li>

                <li>
                  <h4>Basis of the Bargain</h4>
                  <p>
                    The parties agree that the limitations of liability set forth in this section
                    shall survive and continue in full force and effect despite any failure of
                    consideration or of an exclusive remedy. The parties acknowledge that the prices
                    have been set and the Terms entered into in reliance upon these limitations of
                    liability and that all such limitations form an essential basis of the bargain
                    between the parties.
                  </p>
                </li>

                <li>
                  <h4>Additional Rights</h4>
                </li>
              </ol>
            </li>

            <li>
              <h4>Indemnification</h4>
              <ol>
                <li>
                  <h4>By Alpaca Guild Limited</h4>
                  <p />
                </li>

                <li>
                  <h4>Procedure</h4>
                  <p />
                </li>
              </ol>
            </li>

            <li>
              <h3>Termination</h3>
              <p />

              <ol>
                <li>
                  <h4>Term</h4>
                  <p>
                    These Terms will continue to apply until terminated by either you or Alpaca
                    Guild Limited as set forth below (the “Term”).
                  </p>
                </li>

                <li>
                  <h4>Termination by You</h4>
                  <p>
                    If you want to terminate your agreement with Alpaca Guild Limited, you may do so
                    by (a) notifying Alpaca Guild Limited at any time; and (b) closing your account
                    for the Service, where Alpaca Guild Limited has made this option available to
                    you. Your notice should be sent, in writing, to Alpaca Guild Limited’s address
                    set forth below.
                  </p>
                </li>

                <li>
                  <h4>Termination by Alpaca Guild Limited</h4>
                  <p>
                    Alpaca Guild Limited may at any time terminate these Terms if (a) you or any of
                    your Named Users have breached any provision of these Terms (or have acted in a
                    manner that clearly shows you do not intend to, or are unable to, comply with
                    these Terms); (b) Alpaca Guild Limited is required to do so by law (for example,
                    where the provision of the Site or Service to you is, or becomes, unlawful); (c)
                    the provision of the Service to you by Alpaca Guild Limited is, in Alpaca Guild
                    Limited’s opinion, no longer commercially viable; or (d) Alpaca Guild Limited
                    has elected to discontinue the Site or the Service (or any part thereof).
                  </p>
                </li>

                <li>
                  <h4>Effect of Termination</h4>
                  <p>
                    Termination of your account includes: (a) removal of access to all offerings
                    within the Service; (b) deletion of your password and all related information;
                    and (c) barring of further use of the Service. Upon expiration or termination,
                    you shall promptly discontinue use of the Site and the Service. However,
                    Sections 5.6 (IP Ownership), 6 (Payment), 7.1 (License; Warranty), 7.4 (Content
                    Loss), 8 (Third Party Links), 11 (Limited Warranty and Disclaimer), 12
                    (Limitation of Liability), 13 (Indemnification), 14 (Termination) and 15
                    (Miscellaneous) of these Terms will survive any termination
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h3>User Disputes</h3>
              <p>
                Your interactions with other Users found on or through the Service are solely
                between you and such other Users. If there is a dispute between you and any third
                party (including, without limitation, any User), Alpaca Guild Limited is under no
                obligation to become involved; however, we reserve the right, but have no
                obligation, to monitor disputes between you and other Users.
              </p>
            </li>

            <li>
              <h3>Miscellaneous</h3>
              <p />

              <ol>
                <li>
                  <h4>Governing Law</h4>
                  <p />
                </li>

                <li>
                  <h4>Arbitration</h4>
                  <p />
                </li>

                <li>
                  <h4>Forum and Jurisdiction</h4>
                  <p />
                </li>

                <li>
                  <h4>Export</h4>
                  <p />
                </li>

                <li>
                  <h4>Severability</h4>
                  <p />
                </li>

                <li>
                  <h4>Waiver</h4>
                  <p>
                    Any waiver or failure to enforce any provision of these Terms on one occasion
                    will not be deemed a waiver of any other provision or of such provision on any
                    other occasion.
                  </p>
                </li>

                <li>
                  <h4>Release</h4>
                  <p>
                    You hereby release and forever discharge us (and our officers, employees,
                    agents, successors, and assigns) from, and hereby waive and relinquish, each and
                    every past, present and future dispute, claim, controversy, demand, right,
                    obligation, liability, action and cause of action of every kind and nature
                    (including personal injuries, death, and property damage), that has arisen or
                    arises directly or indirectly out of, or relates directly or indirectly to, any
                    interactions with, or act or omission of, other Users.
                  </p>
                </li>

                <li>
                  <h4>Remedies</h4>
                  <p>
                    The parties acknowledge that any actual or threatened breach of Section 5
                    (License Grant and Restrictions) will constitute immediate, irreparable harm to
                    the non-breaching party for which monetary damages would be an inadequate
                    remedy, and that injunctive relief is an appropriate remedy for such breach. If
                    any legal action is brought to enforce these Terms, the prevailing party will be
                    entitled to receive its attorneys’ fees, court costs, and other collection
                    expenses, in addition to any other relief it may receive.
                  </p>
                </li>

                <li>
                  <h4>No Assignment</h4>
                  <p>
                    You will not assign, subcontract, delegate, or otherwise transfer these Terms,
                    or its rights and obligations herein, without obtaining the prior written
                    consent of Alpaca Guild Limited, and any attempted assignment, subcontract,
                    delegation, or transfer in violation of the foregoing will be null and void;
                    provided, however, that you may assign these Terms in connection with a merger,
                    acquisition, reorganisation or sale of all or substantially all of you assets,
                    or other operation of law, without any consent of Alpaca Guild Limited. These
                    Terms shall be binding upon the parties and their respective successors and
                    permitted assigns.
                  </p>
                </li>

                <li>
                  <h4>Force Majeure</h4>
                  <p>
                    Any delay in the performance of any duties or obligations of either party
                    (except the payment of money owed) will not be considered a breach of these
                    Terms if such delay is caused by a labor dispute, shortage of materials, fire,
                    earthquake, flood, or any other event beyond the control of such party, provided
                    that such party uses reasonable efforts, under the circumstances, to notify the
                    other party of the cause of such delay and to resume performance as soon as
                    possible.
                  </p>
                </li>

                <li>
                  <h4>Independent Contractors</h4>
                  <p>
                    Your relationship to Alpaca Guild Limited is that of an independent contractor,
                    and neither party is an agent or partner of the other. You will not have, and
                    will not represent to any third party that you have, any authority to act on
                    behalf of Alpaca Guild Limited.
                  </p>
                </li>

                <li>
                  <h4>Use of name and logo</h4>
                  <p>
                    You hereby grant Alpaca Guild Limited the right to use your name and logo to
                    identify you as a customer of Alpaca Guild Limited in materials promoting the
                    Service. You may request that we cease further use of your name and logo in
                    newly created materials at any time by submitting a written request via email to
                    john@alpaca.co.nz, requesting to be excluded from such materials. Alpaca Guild
                    Limited will use commercially reasonable efforts to honor such request within
                    thirty (30) calendar days after Alpaca Guild Limited&apos;s receipt of such
                    request unless such request is received by Alpaca Guild Limited less than five
                    (5) business days following the date you enter into these Terms, in which case
                    such request will be effective immediately upon receipt by Alpaca Guild Limited.
                  </p>
                </li>

                <li>
                  <h4>Notices</h4>
                  <p>
                    You are responsible for updating your data to provide Alpaca Guild Limited with
                    your most current e-mail address. In the event that the last e-mail address you
                    have provided to Alpaca Guild Limited is not valid, or for any reason is not
                    capable of delivering to you any notices required by these Terms, Alpaca Guild
                    Limited’s dispatch of the e-mail containing such notice will nonetheless
                    constitute effective notice of the changes described on the notice. Any notice
                    provided to Alpaca Guild Limited pursuant to these Terms should be sent to
                    john@alpaca.co.nz or to the following physical address:
                  </p>

                  <p>Alpaca Guild Limited</p>
                  <p>PO Box ???</p>
                  <p>Masterton</p>
                  <p>New Zealand&gt;</p>
                </li>

                <li>
                  <h4>Entire Agreement</h4>
                  <p>
                    These Terms are the final, complete and exclusive agreement of the parties with
                    respect to the subject matters hereof and supersede and merge all prior
                    discussions between the parties with respect to such subject matters. No
                    modification of or amendment to these Terms, or any waiver of any rights under
                    these Terms, will be effective unless in writing.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </Layout>
);

export default Terms;
